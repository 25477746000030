.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.form-outline .form-control~ .form-label {

  background: white !important;
  z-index: 11;
  display: block !important;
  height: 23px;
  font-size: 1.0em;
  transform: none !important;
  margin-top: -30px !important;
  padding-bottom: 17px;
}


.go-back-button {
  font-size: 1.5em;
  cursor: pointer;


}

.app-logo {
  max-width: 100px;

  /*background: #262626;*/
  padding: 20px;
  /*margin-right: 30px;*/

  /*border-radius: 10px;*/
}

.loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  /*background: rgba(0,0,0,.5);*/
  backdrop-filter: blur(5px);

  display: flex;
  align-items: center;
  justify-content: center;

}

.loading-info {
  /*position: absolute;*/
  /*color: rgba(0,0,0,0);*/
}

.app-notifications {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}
.app-notifications .alert {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.text-right {
  text-align: right;
}