.dropdown-selected {
    background: #ccc;
}
.dropdown-option {
    cursor: pointer;
}

.dropdown-option:hover {
    background: #dddddd;
}

.dropdown {
    position: relative;
}
.dropdown-values {
    position: absolute;
    max-height: 300px;
    overflow: auto;
    background: white;
    display: block;
    width: 300px;
    z-index: 100;
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
}

.form-control .form-control {
    /*padding: 0 !important;*/
    padding-left: 0;
    padding-right: 0;
}


.form-control .form-control.dropdown-search::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    opacity: 1;
    color: #666;
}

.dropdown-search.form-control {

}

.dropdown-open {
    cursor: pointer;
}

.dropdown-container-open .form-label {
    color: #1266f1 !important;
}
.dropdown-container-open .form-notch-leading,
.dropdown-container-open .form-notch-middle,
.dropdown-container-open .form-notch-trailing {
    border-width: 2px !important;
    border-color: #1266f1 !important;
}
